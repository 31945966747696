<template>
  <div class="wrapper wrapper_in" :class="{ mini: colapsedMenu && isDesktop }">
    <PanelHeader @toggleMenu="toggleMenu" @openFormModal="openFormModal" :isOPen="isOPen" @close="close" @click="close" @colapseMenu="colapseMenu" />
    <main class="main" :class="{ 'd-flex': user && banReason }">
      <template v-if="banReason">
        <UserBan :reason="banReason" />
      </template>
      <template v-else-if="user && user.id"><slot /></template>
    </main>
    <MenuWrapper @openFormModal="openFormModal" :isOPen="isOPen" :close="close" :colapsedMenu="colapsedMenu" />
    <div class="menu-bg" :class="{ active: isOPen }" @click="close"></div>

    <modal :width="550" height="auto" name="RateModal" :scrollable="true" classes="un-overflow rounded-large">
      <RateModal :manager="user.manager" @closeModal="closeModal" />
    </modal>
  </div>
</template>

<script>
import apiClient from "@/services/AxiosService";
import MenuWrapper from "@/components/MenuWrapper";
import PanelHeader from "@/components/PanelHeader";
import { mapActions, mapGetters } from "vuex";
import { setLangHeader } from "@/services/AxiosService";
import "@/assets/css/vendor/bootstrap.min.css";
import "@/assets/css/main.scss";
import UserBan from "@/components/UserBan.vue";
import RateModal from "@/components/modal/RateModal.vue";

export default {
  name: "PanelLayout",
  components: { PanelHeader, MenuWrapper, UserBan, RateModal },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      banReason: "user/getBanReason",
      selectBrowser: "statistics/getBrowsers",
      selectCountries: "statistics/getCountries",
      selectOS: "statistics/getOS",
      selectSubAccounts: "statistics/getSubAccounts",
    }),
  },
  data() {
    return {
      isOPen: false,
      colapsedMenu: false,
      isDesktop: null,
    };
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      getGuestMode: "user/getGuestMode",
      setCountries: "statistics/handleCountries",
      setBrowsers: "statistics/handleBrowsers",
      setOS: "statistics/handleOS",
      setSubAccounts: "statistics/handleSubAccounts",
      setFeatureFlags: "user/setFeatureFlags",
    }),
    openFormModal() {
      this.$modal.show("RateModal");
    },
    closeModal($event) {
      if ($event && typeof $event == "boolean") {
        this.$alert({
          title: this.$t("sent_success"),
          text: this.$t("your_rating_important"),
          type: "success",
        });
      } else if ($event) {
        this.$alert({
          title: this.$t("sent_error"),
          text: $event,
        });
      }
      this.$modal.hide("RateModal");
    },
    colapseMenu() {
      this.colapsedMenu = !this.colapsedMenu;
    },
    close() {
      if (!this.isOPen) {
        return;
      }
      this.isOPen = false;
    },
    toggleMenu() {
      this.isOPen = !this.isOPen;
    },
    changeLanguage() {
      const newLang = this.$i18n.locale == "ru" ? "en" : "ru";
      this.$i18n.locale = newLang;
      localStorage.setItem("lang", newLang);
    },
    async logOut() {
      await localStorage.setItem("authToken", "");
      await apiClient.get("user/logout");
      localStorage.removeItem("isDarkTheme");
      this.$intercom.shutdown();
      this.$intercom.boot();
      window.location.href = "/";
    },
  },
  created() {
    localStorage.getItem("colapsedMenu") === "true" ? (this.colapsedMenu = true) : (this.colapsedMenu = false);
    this.setFeatureFlags();
    this.getGuestMode();
  },
  mounted() {
    window.innerWidth >= 1200 ? (this.isDesktop = true) : (this.isDesktop = false);

    window.addEventListener("resize", (e) => {
      e.target.innerWidth <= 1200 ? (this.isDesktop = false) : (this.isDesktop = true);
    });
    !this.user.id && this.setUser();
    !this.selectBrowser.length && this.setBrowsers();
    !this.selectCountries.length && this.setCountries();
    !this.selectOS.length && this.setOS();
    !this.selectSubAccounts.length && this.setSubAccounts();
  },
  watch: {
    "$i18n.locale": function () {
      setLangHeader(localStorage.getItem("lang"));
    },
    isOPen() {
      if (this.isOPen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize");
  },
  updated() {
    this.getGuestMode();
  },
};
</script>
<style lang="scss" scoped>
.disable {
  pointer-events: none;
}

.wrapper_in {
  &::v-deep {
    .content_in {
      background: #fff;
      transition: 0.5s;
    }
  }
}
</style>
