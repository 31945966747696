<template>
  <div class="modal-content modal-rate">
    <div class="modal-header">
        <svg v-svg @click="$emit('closeModal')" class="close"
        symbol="modal-close" size="0 0 15 15" role="info"></svg>
    </div>
    <div class="modal-body">
      <div class="modal-rate__form">
        <div class="modal-moderator">
          <div class="modal-moderator__head">
            <div class="modal-moderator__thumb">
              <img :src="require('@/assets/images/partners.png')" alt="manager" />
            </div>
            <div>
              <div class="modal-moderator__name">{{ manager.telegram }}</div>
              <div class="modal-moderator__label">{{ $t("Manager") }}</div>
            </div>
          </div>
          <div class="modal-moderator__contacts">
            <div class="modal-moderator__contact">
              <svg v-svg symbol="telegram2" size="0 0 21 21" role="info"></svg>

              <div class="modal-moderator__label">Telegram</div>
              <a
                :href="telegramLink"
                class="modal-moderator__link"
                target="_blank"
                >{{ manager.telegram }}</a>
            </div>
            <div class="modal-moderator__contact" v-if="manager.skype">
              <svg v-svg symbol="skype" size="0 0 21 21" role="info"></svg>
              <div class="modal-moderator__label">Skype</div>
              <a
                :href="`skype:/${manager.skype}?chat`"
                class="modal-moderator__link"
                >{{ manager.skype }}</a
              >
            </div>
          </div>
        </div>
        <div class="rate-moderation text-center">
          <h3>{{ $t("rate_manager") }}</h3>
          <star-rating
            @rating-selected="setRating"
            v-model="stars"
            :show-rating="false"
            :max-rating="maxRating"
            :star-size="30"
          />
          <div v-if="ratingSuccess" class="rate-moderation__value mb-2">
            <strong>{{ stars }}</strong> / 10
          </div>
          <p class="m-0" v-html="$t('enter_number_stars')" />
        </div>
        <div class="form-item pb-0">
          <div class="form-item__field">
            <div class="form-item__length">
              {{ (message && message.length) || 0 }}/200
            </div>
            <textarea
              maxlength="200"
              v-model="message"
              :placeholder="$t('write_review_text')"
              class="modal-rate__review"
            />
          </div>
        </div>
        <div class="row flex-row-reverse">
          <div class="col-md-6 col-sm-12 pl-0 flex-grow-1 mb-4 mb-lg-0">
            <div class="file-attached">
               
                  <template v-if="file && file.name">
                    <span class="text-right">{{ file.name }}</span>
                    <span @click.stop.prevent="clearFile">
                      <svg v-svg symbol="x" size="0 0 10 10" role="info"></svg>
                    </span>
                  </template>
                  <span class="text-right" v-else>{{
                    $t("file_not_selected")
                  }}</span>
               
              <div class="btn btn-file-grey px-5 py-2 ">
                <label class="cs-file">
                  <input
                    type="file"
                    id="file"
                    ref="file"
                    class="cs-file-input"
                    @change="handleFileUpload"
                  />
                  <svg
                    v-svg
                    symbol="add-file"
                    size="0 0 12 16"
                    role="info"
                  ></svg>
                  <span class="btn__text">{{ $t("file") }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <button
              :disabled="disabled"
              @click="submit"
              class="btn btn-black"
            >
             {{ $t("support.modal.submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarRating from "vue-star-rating";
import SupportService from "@/api/supportService";
import "@/assets/css/_rate-modal.scss";

export default {
  name: "RateModal",
  components: {
    StarRating,
  },
  props: {
    manager: {
      id: null,
      name: "",
      skype: "",
      telegram: "",
    },
  },
  data() {
    return {
      stars: 0,
      fileName: "",
      file: null,
      message: "",
      ratingSuccess: false,
      choseRating: null,
      maxRating: 10,
    };
  },
  computed: {
    telegramLink() {
    const url = `https://t.me/${this.manager.telegram}`.replace('@','')
    return url
  },
    disabled() {
      return !this.ratingSuccess || !this.message?.length;
    },
  },
  methods: {
    submit() {
      let formData = new FormData();
      formData.append("stars", this.choseRating);
      formData.append("review", this.message);
      formData.append("manager_id", this.manager.id);
      this.file ? formData.append("file", this.file) : null;
      
      let params = {
        formData: formData,
      };
      SupportService.createReview(params).then((res) => {
        if (res && res?.data) {
          if (res.data.status === 200) {
            this.$emit("closeModal", true);
          } else {
            this.$emit("closeModal", res.message);
          }
        } else {
          const message = res.data?.message || res;
          this.$emit("closeModal", message);
        }
      });
    },
    setRating() {
      this.choseRating = this.stars;
      this.ratingSuccess = true;
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    clearFile() {
      this.file = {};
      this.$refs.file.value = "";
    },
  },
};
</script>
 