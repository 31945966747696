<template>
  <div class="expand-class">
    <transition
      appear
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      name="expand-height"
    >
      <slot></slot>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TransitionHeight",
  data() {
    return {
      height: null,
    };
  },
  methods: {
    enter(ele) {
      // ele.style.transition = "none";
      // ele.style.position = "absolute";
      // ele.style.visibility = "hidden";
      ele.style.height = "0";

      const height = getComputedStyle(ele.children[0]).height;

      // ele.style.position = "";
      // ele.style.visibility = "";

      setTimeout(() => {
        // ele.style.transition = "";
        ele.style.height = height;
      });
    },
    afterEnter(ele) {
      ele.style.height = "";
    },
    leave(ele) {
      const height = getComputedStyle(ele).height;
      ele.style.height = height;

      requestAnimationFrame(() => {
        ele.style.height = "0";
      });
    },
  },
};
</script>

<style scoped lang="scss">
.expand-height-enter-active,
.expand-height-leave-active {
  transition: height 0.4s;
  overflow: hidden;
}

.expand-height-enter,
.expand-height-leave-to {
  height: 0;
}

.expand-class {
  overflow: hidden;
  position: relative;
  transition-property: "height";
  transition-delay: 0s;
  transition-duration: 0.4s;
}
</style>
