import AxiosService from "@/services/AxiosService";
class DashboardService {
  async getApi() {
    return await AxiosService.get(`/api-keys`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // async getProfitFromSite(payload) {
  //   return await AxiosService.get(`/dashboard/calculate-site-profit`,  { params: payload})
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }
  async setApi(payload) {
    return await AxiosService.post("/api-key", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async deleteApi(payload) {
    return await AxiosService.delete("/api-key", {
      data: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async isEnabledFeatureFlag() {
    return await AxiosService.get(`/get-enabled-feature-flags`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new DashboardService();
