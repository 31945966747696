import AxiosService from "@/services/AxiosService";
class DashboardService {
  getCPLRates() {
    return AxiosService.get("/cpl-rates")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getAllCounties() {
    return AxiosService.get("/dashboard/top")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getUser() {
    try {
      const response = await AxiosService.get(`/user`);
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  async getIncomeChart(payload) {
    return await AxiosService.get("/dashboard/get-income-comparison", {
      params: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getTotalStatistic() {
    return await AxiosService.get("/dashboard/total-statistics")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getStatisticChart(payload) {
    return await AxiosService.get("/dashboard/statistics", { params: payload })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getDiagramChart(payload) {
    return await AxiosService.get("/dashboard/get-diagram-statistics", {
      params: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getTopLanding(payload) {
    return await AxiosService.get("/dashboard/get-top-landings", {
      params: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getHourChart(payload) {
    return await AxiosService.get("/dashboard/statistics-hour", {
      params: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new DashboardService();
