import DashboardService from "@/api/dashboardService";
import apiService from "@/api/apiService";

const state = () => ({
  user: {
    id: null,
    email: "",
    is_g2a_enabled: null,
    name: "",
    avatar: "",
    balance: 0,
    telegram: "",
    skype: "",
    news: 0,
    tickets: 0,
    rid: 0,
    ref_code: "",
    ban_reason: "",
    confirm: true,
    roles: [],
    alert: null,
  },
  ban_reason: "",
  featureFlags: [],
  referralId: "",
  isGuestMode: false,
  isDarkTheme: localStorage.getItem("isDarkTheme") === "true" ? true : false,
});

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getReferralId: (state) => {
    return state.referralId;
  },
  getBanReason: (state) => {
    return state.ban_reason;
  },
  getFeatureFlags: (state) => {
    return state.featureFlags.length > 0
      ? state.featureFlags
      : localStorage.getItem("featureFlags")
      ? localStorage.getItem("featureFlags").split(",")
      : [];
  },
  isDarkTheme: (state) => state.isDarkTheme,
  isGuestMode: (state) => state.isGuestMode,
};

const mutations = {
  setUserStore(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.user = payload.data;
      } else if (payload.status === 403) {
        state.ban_reason = payload.data;
      }
    }
  },
  setFeatureFlags(state, payload) {
    if (payload) {
      state.featureFlags = payload;
      localStorage.setItem("featureFlags", state.featureFlags);
    }
  },
  setUserReferralId(state, payload) {
    if (payload) {
      state.referralId = payload;
    }
  },
  setGuestMode(state, payload) {
    state.isGuestMode = payload;
  },
  setDarkTheme(state, isDark) {
    state.isDarkTheme = isDark;
    localStorage.setItem("isDarkTheme", isDark);
  },
};

const actions = {
  setUser({ commit }) {
    DashboardService.getUser().then((result) => {
      commit("setUserStore", result);
    });
  },
  async setFeatureFlags({ commit }) {
    await apiService.isEnabledFeatureFlag().then((result) => {
      if (result && result.status === 200)
        commit("setFeatureFlags", result.data);
      else
        commit(
          "setFeatureFlags",
          localStorage.getItem("featureFlags").split(",") || []
        );
    });
  },
  setUserReferral({ commit }, payload) {
    commit("setUserReferralId", payload);
  },
  toggleTheme({ commit, state }) {
    const newTheme = !state.isDarkTheme;
    commit("setDarkTheme", newTheme);
  },
  getGuestMode({ state, commit, dispatch }) {
    if (localStorage.getItem("guestToken") && !state.isGuestMode) {
      commit("setGuestMode", true);
    }
    if (state.isGuestMode && !localStorage.getItem("guestToken")) {
      commit("setGuestMode", false);
      dispatch("setUser");
    }
  },
  logOutGuestMode({ commit }) {
    localStorage.removeItem("guestToken");
    commit("setGuestMode", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
