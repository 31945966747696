import AxiosService from "@/services/AxiosService";

class SupportService {
  getTickets() {
    return AxiosService.get("/tickets", {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async createReview(payload) {
    return await AxiosService.post(`/manager-review`, payload.formData)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async createTicket(payload) {
    return await AxiosService.post(`/tickets`, payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setTicketStatus(payload) {
    return await AxiosService.patch(
      `/tickets/${payload.id}/update-status`,
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getTicket(payload) {
    return await AxiosService.get(`/tickets/${payload.id}`)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getMessagesInTicket(payload) {
    return await AxiosService.get(`/tickets/${payload.id}/messages`)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async createMessage(payload) {
    return await AxiosService.post(
      `/tickets/${payload.id}/messages`,
      payload.formData
    )
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getTokenAdmin(payload) {
    return await AxiosService.get(`/admin-login?secret=${payload}`)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new SupportService();
