<template>
  <header :class="{ 'open-menu': isOPen }" class="header">
    <div class="content content_in">
      <button class="header__menu" @click="toggle" title="Burger Menu">
        <div :class="{ active: isOPen }" class="icon-menu">
          <div class="sw-topper"></div>
          <div class="sw-bottom"></div>
          <div class="sw-footer"></div>
        </div>
      </button>
      <router-link class="header__logo" to="/dashboard">
        <img :src="logoUrl" alt="" />
      </router-link>
      <div class="header__manager">
        <img :src="burgerMenuUrl" class="burger-menu" @click="toggleMenu" alt="burger-menu" />
        <div class="manager">
          <div class="manager__item">
            <div class="manager__icon align-center">
              <svg v-svg role="presentation" size="0 0 20 18" symbol="telegram"></svg>
            </div>
            <div class="manager__label">{{ $t("header.your_manager") }}</div>
            <button v-if="user && user.manager" class="manager__link" @click="$emit('openFormModal')">
              {{ user.manager.telegram }}
            </button>
          </div>
          <div class="manager__chat">
            <div class="manager__chat-label">chat</div>
            <a class="manager__link" href="https://t.me/publisher_push_house" target="_blank">@publisher_push_house</a>
          </div>
          <router-link :to="'/create-platform'" @click.native="trackEvent('create_platform_header')" class="btn btn-new _w100">+ {{ $t("platforms.create_platform") }}</router-link>
        </div>
      </div>

      <div v-if="isGuestMode" class="header__guest-label">Guest Mode</div>

      <div class="header__lang language">
        <selectLanguage />
      </div>
      <div class="header__wallet">
        <div class="wallet">
          <div class="wallet__icon align-center">
            <svg v-svg role="presentation" size="0 0 20 18" symbol="usd"></svg>
          </div>
          <div class="wallet__label">{{ $t("header.balance") }}</div>
          <div class="wallet__value">{{ userBalance }}$</div>
        </div>
      </div>

      <div class="header__user">
        <div class="user">
          <button class="user__prev" title="profile avatar" @click.prevent="goToProfile">
            <profile-avatar />
          </button>
        </div>
        <div class="header__news" id="beamer-news">
          <img src="../assets/panel/images/speaker.svg" alt="" />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import apiClient from "@/services/AxiosService";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import "@/assets/css/header.scss";
import SelectLanguage from "@/components/SelectLanguage";
import ProfileAvatar from "@/components/ProfileAvatar";

export default {
  name: "PanelHeader",
  components: { SelectLanguage, ProfileAvatar },
  data() {
    return {
      balance: 0,
      burgerMenuUrl: "",
    };
  },
  props: {
    isOPen: {},
    close: {},
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      banReason: "user/getBanReason",
      isDarkTheme: "user/isDarkTheme",
      isGuestMode: "user/isGuestMode",
    }),
    logoUrl() {
      return this.isDarkTheme ? require("@/assets/images/entry/logo-white.svg") : require("@/assets/images/logo.svg");
    },
    userBalance() {
      return this.user?.balance % 1 === 0 ? this.user.balance?.toFixed(0) : this.user.balance?.toFixed(2);
    },
  },
  mounted() {
    if (localStorage.getItem("colapsedMenu") === "true") {
      this.burgerMenuUrl = require("@/assets/images/icons/burger-menu-close.svg");
    } else {
      this.burgerMenuUrl = require("@/assets/images/icons/burger-menu-open.svg");
    }
  },
  methods: {
    trackEvent(name) {
      this.$gtm.trackEvent({ event: name });
    },
    toggleMenu() {
      this.$emit("colapseMenu");
      this.checkColapseState();
    },
    checkColapseState() {
      if (localStorage.getItem("colapsedMenu") === "true") {
        this.burgerMenuUrl = require("@/assets/images/icons/burger-menu-open.svg");
        localStorage.setItem("colapsedMenu", "false");
      } else {
        this.burgerMenuUrl = require("@/assets/images/icons/burger-menu-close.svg");
        localStorage.setItem("colapsedMenu", "true");
      }
    },
    goToProfile() {
      if (this.user && !this.banReason) {
        this.$emit("close");
        if (this.$route.path !== "/profile") this.$router.push("/profile");
      }
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    toggle() {
      this.$emit("toggleMenu");
    },
    hide() {
      this.close();
    },
    logOut() {
      apiClient.get("/user/logout");
      localStorage.setItem("authToken", "");
      localStorage.removeItem("isDarkTheme");
      this.$intercom.shutdown();
      this.$intercom.boot();
      window.location.href = "/";
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.btn-new {
  display: none;
  font-size: 14px;
  background: #3b8a81;
  padding: 0 20px !important;
  margin-left: 4px;
  &:hover {
    background: #2c746b !important;
  }
  @media screen and (min-width: 1260px) {
    display: flex;
  }
}

.burger-menu {
  align-self: center;
  height: 25px;
  cursor: pointer;
  margin-right: 10px;
}

#beamer-news {
  cursor: pointer;
  order: -1;
  img {
    width: 22px;
    height: auto;
  }
  @media screen and (max-width: 920px) {
    margin-right: 0;
  }
}

.multiselect {
  &.select-language {
    display: none;
  }
}
</style>
