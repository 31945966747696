import * as Sentry from "@sentry/vue";

export async function captureSentryError(
  error,
  payload,
  status,
  data,
  url,
  headers,
  method
) {
  Sentry.captureException(error, {
    extra: {
      requestPayload: payload, // payload запроса
      responseStatus: status, // статус ответа
      responseData: data, // данные ответа (если есть)
      requestUrl: url, // URL запроса
      requestHeaders: headers, // заголовки запроса
      requestMethod: method, // метод запроса
    },
  });
  await Sentry.flush(1000);
}
