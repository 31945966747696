const MobileDetection = {
  install(Vue) {
    Vue.prototype.$isMobile = window.innerWidth <= 450;
    window.addEventListener('resize', () => {
      Vue.prototype.$isMobile = window.innerWidth <= 450;
    });
  },
};

export default MobileDetection;
