<template>
  <div class="ban-container">
    <div class="ban-container__block">
      <img :src="require('@/assets/images/icons/user-ban.svg')" />

      <span class="ban-container__title">{{ $t("user_ban") }}</span>
      <span class="ban-container__text">{{ reason }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserBan",
  props: {
    reason: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.ban-container {
  width: 100%;
  min-height: 75vh;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f9;
  border-radius: 15px;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    color: #000109;
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
  }

  &__text {
    font-size: 18px;
  }
}
</style>